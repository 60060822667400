import { Calendar, Bookmark, Award, Messages1, Bank, MoneyRecive, Tree, ShoppingBag, Rank, Document, User, People, Headphone  } from 'iconsax-react';
import IntlMessages from "../../layout/components/lang/IntlMessages";
import { Ri24HoursLine, RiHistoryLine, RiListIndefinite, RiRobotLine, RiTrademarkLine } from 'react-icons/ri';
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { Children } from 'react';

const apps = [
    {
        header: "",
    },
    {
        id: "configuracion",
        title: "Configuración del Sistema",
        icon: <Ri24HoursLine />,
        navLink: "/config_app",
    },
    {
        id: "usuarios",
        title: "Usuarios",
        icon: <User />,
        children: [
            {
                id: "usuarios",
                title: "Usuarios",
                navLink: "/usuarios",
            },
            {
                id: "bank_account",
                title: "Cuentas bancarias",
                navLink: "/users/wallets",
            },
            {
                id: "depositos",
                title: "Depositos",
                navLink: "/users/deposits",
            },
        ]
    },
    {
        id: "arbitraje",
        title: "Arbitraje",
        icon: <RiRobotLine />,
        children: [
            {
                id: "licencias",
                title: "Licencias Robots",
                navLink: "/paquetes",
            },
        ]
    },
    {
        id: "retiros",
        title: "Retiros",
        icon: <AiOutlineFundProjectionScreen />,
        navLink: "/retiros",
    },
    {
        id: "soporte",
        title: "Soporte",
        icon: <Headphone />,
        navLink: "/soporte",
    },
];

export default apps;
